// JS Goes here - ES6 supported

import "./css/main.scss";
import { MDCRipple } from "@material/ripple/index";
import { MDCTopAppBar } from '@material/top-app-bar/index';

import {MDCChipSet} from '@material/chips';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js');
  });
}

[...document.querySelectorAll(".mdc-button")].forEach(btn => new MDCRipple(btn));

[...document.querySelectorAll(".mdc-icon-button")].forEach(btn => {
  const iconButtonRipple = new MDCRipple(btn);
  iconButtonRipple.unbounded = true;
});

[...document.querySelectorAll(".mdc-chip-set")].forEach(chipset => new MDCChipSet(chipset));

const topBar = document.querySelector('.mdc-top-app-bar')
const topAppBar = new MDCTopAppBar(topBar);

//for top bar colouring when floating
topAppBar.scrollTarget_.addEventListener('scroll', _ => {
  if (window.scrollY - topBar.getBoundingClientRect().height > 0) {
    topBar.classList.add('floating');
  } else {
    topBar.classList.remove('floating');
  }
}, { passive: true });
